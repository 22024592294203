import React from 'react';
import { Section } from 'components/organisms';
import { Contact, ContactSecond, Hero } from './components';
import { Box } from '@material-ui/core';

const mapData_1 = [
  {
    location: {
      y: 45.37045,
      x: -75.77139,
      address: '356 Woodroffe Ave, Ottawa, ON K2A 4E2, Canada',
    },
  },
];
const mapData_2 = [
  {
    location: {
      y: 43.851670,
      x: -79.388640,
      address: '45B West Wilmot St, Suite 202, Richmond Hill, ON L4B 2P3, Canada',
    },
  },
];

const ContactPage = () => (
  <Box mt={8}>
    <Hero />
    <Section>
      <Contact data={mapData_1} />
    </Section>
    <Section>
      <ContactSecond data={mapData_2} />
    </Section>
  </Box>
);

export default ContactPage;
